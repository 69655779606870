<template>
  <b-row
    id="result-header"
    class="scroll-container flex-nowrap gap-2 overflow-auto pb-50 px-50"
    no-gutters
  >
    <b-col
      v-for="(itinerary, itineraryIndex) in getSearchPayloadArray"
      :key="itineraryIndex"
    >
      <BButton
        :variant="tabIndex === itineraryIndex ? 'primary' : 'outline-primary'"
        class="p-75 px-md-1 d-flex justify-content-around align-items-center text-nowrap bg-white"
        block
        @click="handleSetTabIndex(itineraryIndex)"
      >
        <div
          :class="`fw-700 d-flex text-nowrap mr-1 align-items-center
          ${isMobileView ? 'font-medium-1' : 'font-medium-4'}
          `"
        >
          <IAmIcon
            :icon="itineraryIndex === 0 ? 'trainGo' : 'trainBack'"
            :size="isMobileView ? '20' : '28'"
            class="mr-25"
          />

          <span class="pt-25">
            {{ itineraryIndex === 0 ? 'Chiều đi: ' : 'Chiều về: ' }}
          </span>
        </div>

        <div>
          <div class="fw-700 font-medium-2">
            {{ itinerary.departure }}-{{ itinerary.arrival }} {{ convertISODateTime(itinerary.departDate).dayAndMonth }}
          </div>
          <!-- v-if="selectedTrips[itineraryIndex] && selectedTrips[itineraryIndex][0]" -->
          <div>
            Tàu SE7, Ngày 00/00/0000
          </div>
        </div>
      </BButton>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import { convertISODateTime } from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BButton, BRow, BCol,
  },
  setup() {
    const {
      tabIndex,
      getSearchPayloadArray,
      handleSetTabIndex,
    } = useTrainHandle()

    return {
      tabIndex,
      getSearchPayloadArray,
      handleSetTabIndex,
      convertISODateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
#result-header {
    position: sticky;
    top: 50px;
    z-index: 10;
}

/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>
